<template>
  <div>
    <!-- Branding options -->

    <b-row>
      <b-col>
        <h1>Styling</h1>
      </b-col>
      <b-col cols="auto">
        <b-button
          class="mb-5 save-button"
          @click="saveAll()"
          :disabled="saveDisabled"
        >
          Save
        </b-button>
      </b-col>
    </b-row>

    <b-alert variant="warning" :show="saveMessage !== ''">{{
      this.saveMessage
    }}</b-alert>
    <b-row>
      <!-- LOGO  -->
      <b-col>
        <b-form-group label-size="lg" label="Logo">
          <div class="image-container color-primary">
            <b-img
              class="color-primary styling-image"
              center
              thumbnail
              fluid
              :src="previewLogo"
            ></b-img>
          </div>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="image-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-file
              @change="onFileChange($event, 'logo')"
              v-model="styling.additional.logo"
              :placeholder="styling.additional.logo"
              id="form-image-logo"
              accept=".jpg, .png, .gif"
            ></b-form-file>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- BACKGROUND IMAGE  -->
      <b-col>
        <b-form-group label-size="lg" label="Background image">
          <div class="image-container color-primary">
            <b-img
              class="color-primary styling-image"
              center
              thumbnail
              fluid
              :src="previewBackground"
            ></b-img>
          </div>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="image-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-file
              id="form-image-background"
              @change="onFileChange($event, 'background')"
              v-model="styling.additional.background"
              :placeholder="styling.additional.background"
              accept=".jpg, .png, .gif"
            ></b-form-file>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- COLORS  -->
    <div>
      <b-form-group
        class="mt-5"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        label-cols-lg="2"
        label="Branding colors"
      >
        <b-form-group
          id="fieldset-1"
          :description="`Choose the primary color for your theme.`"
          label="Primary color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.primary"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the secondary color for your theme.`"
          label="Secondary color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.secondary"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the tertiary color for your theme.`"
          label="Tertiary color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.tertiary"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-class="font-weight-bold pt-0"
        label-cols-lg="2"
        label="Text colors"
      >
        <b-form-group
          id="fieldset-1"
          :description="`Choose the header text color for your theme.`"
          label="Header text color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.text_color_header"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the title text color for your theme.`"
          label="Title text color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.text_color_title"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the body text color for your theme.`"
          label="Body text color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.text_color_body"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-class="font-weight-bold pt-0"
        label-cols-lg="2"
        label="Semantic colors"
      >
        <b-form-group
          id="fieldset-1"
          :description="`Choose the success color for your theme.`"
          label="Success color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.success"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the danger color for your theme.`"
          label="Danger color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.danger"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :description="`Choose the warning color for your theme.`"
          label="Warning color"
          label-for="input-1"
          class="admin-label"
        >
          <b-form-input
            id="input-1"
            v-model="styling.warning"
            type="color"
            trim
          ></b-form-input>
        </b-form-group>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios.plugin";

export default {
  data() {
    return {
      saveDisabled: true,
      saveMessage: "",
      busy: false,
      backgroundToggle: true,
      backgroundImage: "",
      logo: "",
      fileLogo: null,
      fileBackground: null,
      previewLogo: null,
      previewBackground: null,
      logoChanged: false,
      backgroundChanged: false
    };
  },
  watch: {
    styling: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    styling() {
      return this.$store.state.Styling.styling;
    },
  },
  methods: {
    onFileChange(event, type) {
      if (type == "logo") {
        this.logoChanged = true
        this.fileLogo = event.target.files[0];
        this.previewLogo = URL.createObjectURL(this.fileLogo);
      } else if (type == "background") {
        this.backgroundChanged = true
        this.fileBackground = event.target.files[0];
        this.previewBackground = URL.createObjectURL(this.fileBackground);
      }
    },
    async saveAll() {
      if (this.logoChanged) {
        this.styling.additional.logo = process.env.VUE_APP_FILE_URL + await this.uploadFile(this.fileLogo)
      } else if (this.backgroundChanged) {
        this.styling.additional.background = process.env.VUE_APP_FILE_URL + await this.uploadFile(this.fileBackground)
      }
      await this.$store.dispatch("Styling/update", this.styling);
      await this.$store.dispatch("Styling/generate");
      this.$root.$bvToast.toast("Styling saved.", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
    },
    async uploadFile(file) {
      console.log(file);
      let formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/files/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "json",
        }
      );
      return response.data.url;
    },
  },
  created() {
    this.$store.dispatch("Styling/generate");
    this.previewLogo = this.styling.additional.logo
    this.previewBackground = this.styling.additional.background
    this.saveDisabled = true;
    this.saveMessage = "";
  },
};
</script>
<style lang="scss">
.label-checkbox {
  display: flex;
  align-items: center;
}

.image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.styling-image {
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
